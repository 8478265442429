@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Open Sans',sans-serif ;
    background: #fff;
    color: #333;
    line-height: 1.6;
}

ul {
    list-style: none;
}

a {
    color: #333;
    text-decoration: none;
}

h1, h2 {
    font-weight: 300;
    line-height: 1.2;
}

p {
    margin: 10px 0;
}

img {
    width: 100%;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    color: #222222;
    width: 100%;
    height: 130px;
    position: fixed;
    top: 0px;
    padding: 0 500px;
    transition: 0.5s;
    border-bottom: #24D9F5 10px solid;
}

.navbar a {
    color: #222222;
    padding: 10px 20px;
    margin: 0 5px;
}
.navbar a:hover {
    border-bottom: #24D9F5 2px solid;
}
.navbar ul {
    display: flex;
    padding-top: 20px;
    font-size: 20px;
}
.navbar h4 {
    background-color: #24D9F5;
    color: #fff;
    text-align: center;
    padding: 5px 5px 0px 5px
}
.navbar h5 {
    background-color: #24D9F5;
    color: #fff;
    text-align: center;
    padding: 0px 5px 5px 5px;
}
.hero {
    background: url('images/pexels-binyamin-mellish-186077.jpg') no-repeat center center/cover;
    height: 120vh;
    position: relative;
    color: #fff;
}

.hero .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    padding: 0 20px;
    font: bolder;
}
.content h1{
    background-color: #0D2E32;
    padding: 20px;
    opacity: 0.8;
}
.content h2 {
    background-color: #0D2E32;
    padding: 20px 160px;
    opacity: 0.8;
}
.logo img {
    margin-top: 15px;
    width: 250px;

}

.hero * {
    z-index: 10;
}

/* Icons */
.icons {
    /* padding: 60px; */
    background: url('images/service5.png') no-repeat center center/cover;
}
.icons h3 {
    font-weight: bold;
    margin-bottom: 30px;
}

.icons img {
    width: 250px;
    padding: 50px;
}
.service-header {
    color: #fff;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.flex-items {
    color: #fff;
    font-size: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.flex-columns.flex-reverse .row {
    flex-direction: row-reverse;
}

.flex-columns .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.flex-columns .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}


.flex-columns .column .column-1,
.flex-columns .column .column-2 {
    height: 100%;
}
.flex-columns img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.flex-columns .column-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px;

}

.flex-columns h2 {
    font-size:40px;
    font-weight: 100;
}
.flex-columns h4 {
    margin-bottom: 10px;
}

.flex-columns p {
    margin: 20px 0px;
}

.cases img:hover {
    opacity: 0.7;
 }

 .section-header {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #24D9F5;
    margin-bottom: 10px;
    
}
.section-header h2 {
    color: #fff;
    font-size: 40px;
    margin: 20px 0;
}


/* Flex Grid */
.flex-grid .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}

.flex-grid .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}
 .flex-items.testimonials{
    color:#24D9F5;
    font-size: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.testimonials img{
    width: 50px;
    margin:10px;
}

.testimonial-header {
    color: #24D9F5;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0 20px;
    text-align: center;
}

/* Footer */

 .flex-items.footer-end {
    background: #222222;
    display: flex;
    color:#24D9F5;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: left;
    font-size: 20px;
    height: 100%;
    margin-top: 20px;
    padding: 0px;
    border-top: #24D9F5 10px solid;
    padding-bottom: 50px;
}
.flex-items.footer-end p {
    color: #fff;
}
.copy {
    background: #222222;
    display: flex;
    color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: -10px;
}

.footerLogo img{
    max-width: 500px;
    height: 300px;
    padding-top: 0px;
    padding-right: 150px;
    flex-direction: left;
    align-items: left;
}

@media (max-width: 768px) {
    .navbar {
        display: flex;
        flex-direction: column;
        height: 250px;
        padding:0 20px;
    }
    .logo{
       width: 80%;
    }
    .logo img {
        margin: 0;
    }
    .navbar ul {
        padding:0%;
        margin-right: 10px;
        align-items: center;

    }
    .navbar a {
        padding: 0px 10px;
        margin: 0;
    }

    .navbar a:hover {
        color: #24D9F5;
        text-decoration: none;
    }
    .flex-items {
        flex-direction: column;
    }
    .flex-columns .column, .flex-grid{
        flex: 100%;
        width: 100%;
    } 
    .flex-items.footer-end {
        flex-direction: column;
        align-items: center;
        padding-right: 100%;
    }

    .footerLogo img {
        height: auto;
    }
    .contact {
        margin-left: 350px;
    }
 }
 